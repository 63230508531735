import './App.css';
import { Routes, Route, Link } from "react-router-dom";
import Navigation from './Components/Navigation';
import New from './Components/New';
import Style from './Components/Styles';
import About from './Components/About';
import Media from './Components/Media';
import Footer from './Components/Footer';

function App() {
  return (
    <div className="App">
      <Navigation />
      <Routes>
        <Route path="/" element={<New />} />
        <Route path="style" element={<Style />} />
        <Route path="about" element={<About />} />
        <Route path="media" element={<Media />} />
      </Routes>
      <Footer />

    </div>
  );
}

export default App;
