import React from "react";
import { Carousel } from "react-bootstrap";

export default function MainCarousel() {
    return (
        <section className="container w-100 my-1"> 
            <Carousel>
                <Carousel.Item>
                    <img
                        className="d-block w-100 rignholder"
                        src="/assets/ringholder-editted.jpeg"
                        alt="First slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 dog4"
                        src="/assets/dog4-editted.jpeg"
                        alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 darkblue"
                        src="/assets/DarkBlue.png"
                        alt="Third slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 plastic1"
                        src="/assets/PlasticOne.png"
                        alt="Four slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 plastic2"
                        src="/assets/PlasticTwo.png"
                        alt="Five slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100 swars2"
                        src="/assets/SwarsTwo.png"
                        alt="Six slide"
                    />
                </Carousel.Item>
            </Carousel>
        </section>
    )
}