import React from "react";
import { Carousel } from "react-bootstrap";

export default function DetailedItem() {
    
    return (
        <>
            <section className="container w-100 my-5 d-flex flex-row flex-wrap justify-content-center"> 
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/startreck 1.jpeg" class="card-img-top" alt="Startreck1"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/startreck 2.jpeg" class="card-img-top" alt="Startreck2"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/startreck 3.jpeg" class="card-img-top" alt="Startreck3"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/dog1.jpeg" class="card-img-top" alt="Dog1"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/dog3.jpeg" class="card-img-top" alt="Dog3"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/dog2.jpeg" class="card-img-top" alt="Dog2"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/dog4.jpeg" class="card-img-top" alt="Dog4"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/plastic1.jpeg" class="card-img-top" alt="Plastic1"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/plastic 2.jpeg" class="card-img-top" alt="Plastic2"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/ring holder1.jpeg" class="card-img-top" alt="Ringholder1"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/ring hold2.jpeg" class="card-img-top" alt="Ringholder2"/>
                </div>
                <div className="card mx-5 my-5" style={{ "width": "33rem" }}>
                    <img src="/assets/ring hold 3.jpeg" class="card-img-top" alt="Ringholder3"/>
                </div>

            </section>
            {/* <section className="container w-100 my-5 d-flex justify-content-center"> 
                <div className="card" style={{ "width": "33rem" }}>
                    <Carousel indicators={false}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/startreck 1.jpeg"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/startreck 2.jpeg"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/startreck 3.jpeg"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section> */}
            {/* <section className="container w-100 my-5 d-flex justify-content-center"> 
                <div className="card" style={{ "width": "33rem" }}>
                    <Carousel indicators={false}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/dog1.jpeg"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/dog2.jpeg"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/dog3.jpeg"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/dog4.jpeg"
                                alt="Fourth slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section> */}
            {/* <section className="container w-100 my-5 d-flex justify-content-center"> 
                <div className="card" style={{ "width": "33rem" }}>
                    <div className="card" style={{ "width": "33rem" }}>
                        <Carousel indicators={false}>
                            <Carousel.Item>
                                <img
                                    className="d-block w-100"
                                    src="/assets/camera hold1.png"
                                    alt="First slide"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </div>
                </div>
            </section>         */}
            {/* <section className="container w-100 my-4 d-flex justify-content-center"> 
                <div className="card" style={{ "width": "33rem" }}>
                    <Carousel indicators={false}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/plastic1.jpeg"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/plastic 2.jpeg"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                 </div>
            </section> */}
            {/* <section className="container w-100 my-5 d-flex justify-content-center"> 
                <div className="card" style={{ "width": "33rem" }}>
                    <Carousel indicators={false}>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/ring holder1.jpeg"
                                alt="First slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/ring hold2.jpeg"
                                alt="Second slide"
                            />
                        </Carousel.Item>
                        <Carousel.Item>
                            <img
                                className="d-block w-100"
                                src="/assets/ring hold 3.jpeg"
                                alt="Third slide"
                            />
                        </Carousel.Item>
                    </Carousel>
                </div>
            </section> */}
        </>
    )
}