import React from "react";

export default function Styles() {
    return (
        <section className="container w-100 my-1 mx-auto justify-content-center"> 
          <div id="styles">
            <img className="d-block w-100 my-5" src="/assets/WaterColors.png" alt="First image"></img>
            <img className="d-block w-100 my-5" src="/assets/NikeLux.png" alt="Second image"></img>
            <img className="d-block w-100 my-5" src="/assets/DarkBlue.png" alt="Third image"></img>
            <img className="d-block w-100 my-5" src="/assets/CustomLense.png" alt="Forth image"></img>
            <img className="d-block w-100 my-5" src="/assets/ColorPallete.png" alt="Fifth image"></img>
          </div>
      </section>
    )
}