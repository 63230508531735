import React from "react";

export default function Footer() {
    const today = new Date('June 5, 22 00:11:57');
    const currYear = today.getFullYear();
    return (
        <>  
            <p className="text-center my-5">©Copyright {currYear}, Hi-Case</p>
        </>
    )
}