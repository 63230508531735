import React from "react";
import MainCarousel from "../MainCarousel";
import DetailedItem from "../DetailedItem";

export default function New() {
    return (
        <>
            <MainCarousel />
            <DetailedItem />
        </>
    )
}