import React from "react";
import { Nav } from "react-bootstrap";

export default function Navigation(){
    return(
        <header className="my-5">
            <nav className="my-5">
                <ul className="nav justify-content-center">
                    <li className="nav-item">
                        <a className="nav-link active m-2" aria-current="page" href="/">New</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link m-2" href="about">About</a>
                    </li>
                    <li className="nav-item">   
                        <a className="nav-link m-2" href="style">Styles</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link m-2" href="media">Media</a>
                    </li>
                </ul>
            </nav>
        </header>
    )
}